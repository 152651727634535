<template lang="">
    <div>
        <card>
            <template #header> Geçmiş </template>
            <div class="row">
                <div class="d-flex align-items-center justify-content-between search-list-area">
                    <div class="d-flex align-items-center justify-content-between search-input mb-4">
                    <div class="col-md-2 col-12">
                        <select v-model="list_length" class="form-control ml-2">
                        <option value="5" selected>Seçiniz</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        </select>
                    </div>
                    <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
                    <div class="d-flex justify-content-end">
                        <b-button
                        variant="outline-primary"
                        class="d-flex d-md-inline-block ml-2 w-md-auto w-100"
                        v-b-toggle.sidebar-right
                        >Filtrele
                        </b-button>
                    </div>
                    </div>
                </div>
                
                <div class="custom-list">
                    <List
                    :header="header"
                    :data="items"
                    :pagination="pagination"
                    :loading="loading"
                    @updatePage="updatePage"
                    moduleName="Mülkler Listesi"
                    :moduleCreateEvent="true"
                    :moduleSearch="search"
                    >
                    
                    <template v-slot:item.fiyat="{ item }">
                        <p class="text-dark">{{ item.fiyat }} ₺</p>
                    </template>
                    <template v-slot:item.aciklama="{item}">
                        <Moretext :value="item" />
                    </template>
                    <template v-slot:item.tip ="{item}">
                    <b-badge variant="success" v-if="item.tip === 1">Bina</b-badge>
                    <b-badge variant="danger" v-if="item.tip === 2">Arsa</b-badge>
                    </template>
                    
                    <template v-slot:item.action="{ item }" class="p-0">
                        <div class="d-flex justify-content-end">
                        <router-link
                            :to="`/mulkler/mulk-detay/${item.id}/`"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <button
                            class="btn"
                            :href="href"
                            @click="navigate"
                            ><i class="flaticon2-arrow text-dark p-0"></i
                            ></button>
                        </router-link>
                        <router-link
                            :to="`properties/detail`"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <button
                            class="btn"
                            :href="href"
                            @click="navigate"
                            ><i class="flaticon2-trash text-danger p-0"></i
                            ></button>
                        </router-link>
                        
                        </div>
                    </template>
                    </List>
                    <!-- Create and Actions Popups -->
                </div>
            </div>
        </card>


        <div class="notes-sidebar top-full">
          <b-sidebar id="sidebar-right" right>
            <div
              class="card dveb-gray-card aside-gray-card tab-card"
              style="overflow-y: auto"
            >
              <b-tabs content-class="mt-3">
                <b-tab content-class="tab-content" title="Filtre" active>
                  <div class="form-group">
                    <label for="user">Kullanıcı</label>
                    <select name="" id="user" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">Refik Selim Altıok</option>
                      <option value="">Rasim</option>
                      <option value="">Ahmet Berke Aydın</option>
                      <option value="">Mert Önal</option>
                    </select>
                  </div>

                    <div class="form-group">
                        <label for="start_date">Başlangıç Tarihi</label>
                        <b-form-datepicker id="start_date" label-today="Bugün" label-today-button="Bugünü Seç" label-no-date-selected="Tarih Seçilmedi" today-button v-model="value" class="mb-2"></b-form-datepicker>
                    </div>

                    <div class="form-group">
                        <label for="end_date">Bitiş Tarihi</label>
                        <b-form-datepicker id="end_date"  label-today="Bugün" label-today-button="Bugünü Seç" label-no-date-selected="Tarih Seçilmedi" today-button v-model="value" class="mb-2"></b-form-datepicker>
                    </div>

                  <div class="row">
                    <div class="col-md-6 col-12 mt-4">
                      <b-button variant="light" class="w-100"> Temizle </b-button>
                    </div>
                    <div class="col-md-6 col-12 mt-4">
                      <b-button variant="light" class="w-100"> Uygula </b-button>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-sidebar>
        </div>
</div>
</template>




<script>


export default {
  name: "customerList",
  
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames : ["Hazırlık","Satış","Devir"],
      header: [
        { text: "#", value: "id", size: "50px" },
        { text: "Kullanıcı", sortable:true,value: "user" },
        { text: "Kayıt", sortable:true,value: "text" },
        { text: "Tarih", value: "date" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList:[],
      list_length:5,
      openFullTextModal:false
    };
  },
  components:{
    Moretext : () =>import("@/components/global/Moretext")
  },
  created() {
    this.search = this.$route.query.search
    this.search = this.search.toLowerCase()
    this.getList();

  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          user: "Refik Selim Altıok",
          text: "Mülk'e görsel eklendi. Eklenen görsel ID: 3461",
          date: "21 Temmuz 2023 | 15:09"
        },
        
      ];
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: Math.ceil(this.items?.length/this.list_length),
        total_items: this.items.length,
        list_length: this.list_length
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }



      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `property/log?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show('modal-deleteOperation');
      let index = this.notes.indexOf(key)
      this.notes.splice(index,1)
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function () {
      this.getList();
    },
    list_length:function(){
      this.getList()
    },
    $route:function(){
      this.search = this.$route.query.search
    this.search = this.search.toLowerCase()
    }
    

  },
 
};
</script>
